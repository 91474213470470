* {
  box-sizing: border-box;
}

body {
  background-color: #222;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

ul,
ol {
  list-style-type: none;
  list-style-position: inside;
  margin: 1.6rem 0;
  padding: 0;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  height: 48px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 48px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  color: #3df;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.moves-btn {
  width: 144px;
  border: 1px #aaa solid;
  border-radius: 25px;
  background-color: #ada;
  padding: 8px;
  color: navy;
  font-weight: bold;
  margin-bottom: 8px;
}

.moves-btn:hover {
  cursor: pointer;
  background-color: #000;
  color: #ddd;
}

